.tags-input-container{
    padding: .5em;
    border-radius: 3px;
    width: min(80vw, 600px);
    margin-top: 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
}

.tag-item{
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: .5em .75em;
    border-radius: 10px;
    color : rgba(0,0,0,.6);
    font-weight: 700;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
}

.tag-item-selected {
    color : white;
    background-color: rgb(0, 24, 86);
}

.tag-item .delete {
    padding-left : 5px;
}
/* .tag-item .close{
    height: 20px;
    width: 20px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
} */

.tags-input{
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
}