.App > .loader {
	max-width: 300px;
	margin: auto;
	padding-top: 100px;
}

.loadingShow {
	position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
}

.loadingLocalShow {
	position: absolute;
    width:100%;
	height: 100%;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
}

.loadingHidden {
    display: none
}

.textDarkBlue {
	color : #001856 !important
}

.textGreen {
	color : #28a745 !important
}

.textRed {
	color : red !important
}

.btnDarkBlue {
	background-color : rgb(0, 24, 86) !important;
	color: white !important;
	border-color : rgb(0, 24, 86) !important;
}

.btnDarkBluev2{
	padding : 10px;
	background : rgb(0, 24, 86);
	color: white;
	border-radius: 9px;
	margin: 2px;
}

.btnRed {
	background-color : red !important;
	color: white !important;
}

.quillEditor .ql-container{
	min-height: 200px;
}

.innerHTMLContent {
	width: 100%;
}

.innerHTMLContent > * > img {
	width: 100%;
}